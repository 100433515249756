document.addEventListener("DOMContentLoaded", () => {
  const blocks = document.querySelectorAll(".block-testimonial .swiper");

  if (blocks.length === 0) {
    return;
  }

  [...blocks].forEach((item) => {
    const swiper = new Swiper(item, {// eslint-disable-line
      // Optional parameters
      loop: true,
      lazy: true,
      centeredSlides: true,
      pagination: {
        clickable: true,
        el: ".swiper-pagination",
      },
    });
  });
});
